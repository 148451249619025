import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import logo from "../../assets/logo.svg";
import stripePaymentIllustration from "../../assets/stripe-payment-illustration.svg";
import poweredByStripe from "../../assets/poweredByStripe.svg";
import "./SignUpPage.scss";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../components/CheckoutForm/CheckoutForm";
import Checkmark from "../../components/Checkmark/Checkmark";
import Footer from "../../components/Footer/Footer";
import axiosClient from "../../axiosClient";
import TermsAndConditions from "../Legal/TermsAndConditions";
import toast from "react-hot-toast";

function SignUpPage() {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [subscriptionType, setSubscriptionType] = useState<'monthly' | '6_monthly'>("6_monthly");

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      const fetchStripeConfig = async () => {
        const r = await axiosClient.get('payments/config')
        const { publishableKey } = r.data
        setStripePromise(loadStripe(publishableKey));
      }

      fetchStripeConfig()
    } catch (err) {
      toast.error("Error occured whilst loading page. Please seek support!");
      console.error(err);
    }
  }, []);

  useEffect(() => {
    console.log(subscriptionType);
  }, [subscriptionType])

  return (
    <>
      <NavBar />
      <div id="SignUpPage">
        <div className="site-container">
          <div className="order-summary-wrapper">
            <div className="order-summary-inner">
              <div className="big-logo-wrapper">
                <img src={logo} className="big-logo" alt="" />
              </div>

              <div className="inner-cols-wrapper">
                <div className="order-summary-col">
                  <form id="form-wrapper">
                    <h3 className="order-summary-section-title">
                      Order Summary
                    </h3>

                    <div className="payment-plan-section">
                      {/* <h5>Payment Plan</h5> */}

                      {/* TODO: make payment-plan-option card all clickable, so clicks checks/uncheck the input checkbox inside */}
                      <div className="payment-plan-option" onClick={(e) => {
                        (document.getElementById('6_monthly') as HTMLInputElement).checked = true;
                        (document.getElementById('monthly') as HTMLInputElement).checked = false;
                        setSubscriptionType("6_monthly");
                      }}>
                        <div>
                          <input
                            className="checkbox-round"
                            type="checkbox"
                            name="6_monthly"
                            id="6_monthly"
                            defaultChecked
                          />
                        </div>
                        <label htmlFor="6_monthly">Billed 6-monthly</label>
                        <p className="payment-plan-price">£100</p>
                        <p className="payment-plan-description">PER 6 MONTHS</p>
                      </div>

                      <div className="payment-plan-option" onClick={(e) => {
                        (document.getElementById('6_monthly') as HTMLInputElement).checked = false;
                        (document.getElementById('monthly') as HTMLInputElement).checked = true;
                        setSubscriptionType("monthly");
                      }}>
                        <div>
                          <input
                            className="checkbox-round"
                            type="checkbox"
                            name="monthly"
                            id="monthly"
                          />
                        </div>
                        <label htmlFor="monthly">Billed monthly</label>
                        <p className="payment-plan-price">£35</p>
                        <p className="payment-plan-description">PER MONTH</p>
                      </div>
                    </div>

                    <h4 className="order-summary-section-title">
                      Sign up Details
                    </h4>

                    <p>Please confirm the following:</p>
 
 
                    <div>
                      <input type="checkbox" name="at_least_13_years_old_check" id="at_least_13_years_old_check" style={{
                        marginRight: '5px'
                      }} defaultChecked={false} required/>
                      <label htmlFor="at_least_13_years_old_check">I am 13+ years old</label>
                    </div>

                    <p style={{
                      fontSize: 'smaller',
                      marginTop: '20px',
                      color: 'grey'
                    }}>We currently require students to be at least 13 years old to sign up. This is because for students under the age of 13, we require a parent or guardian to consent the sign up to comply with the UK's GDPR guidelines. We hope to add support for this in the future.</p>
                    
                    <div className="user-details-section">
                      <h5 style={{
                        marginTop: '30px'
                      }}>Student details</h5>
                      <div className="user-details-name-wrapper">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required
                        />
                        <input
                          type="text"
                          name="surname"
                          placeholder="Surname"
                          required
                        />
                      </div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        required
                      />


                      <h5 style={{
                        marginTop: '30px'
                      }}>Student login details</h5>

                      {/* <p style={{
                        fontSize: 'smaller',
                        color: 'grey'
                      }}>For security reasons, your username must NOT contain your first name, surname, or the part of your email before the @ sign.</p>
                       */}
                      <input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        autoComplete="username"
                        required
                      />
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        required
                      />
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm password"
                        required
                      />




                    </div>


                    <div style={{
                      display: 'flex',
                      marginBottom: '30px',
                    }}>
                      <input type="checkbox" name="agree_to_terms" id="agree_to_terms" style={{
                        marginRight: '5px'
                      }} defaultChecked={false} required/>
                      <label htmlFor="agree_to_terms" style={{
                        position: 'relative',
                        top: '12px'
                      }}>I agree to MasterQ's <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>, <a href="/terms-of-use" target="_blank">Terms of Use</a>,
                      and our <a href="privacy-policy" target="_blank">Privacy Policy</a>.</label>
                    </div>

                    <h4 className="order-summary-section-title">
                      Billing Details
                    </h4>

                    <div className="billing-details-section">
                      {stripePromise && (
                        <Elements
                          stripe={stripePromise}
                          options={{
                            mode: "subscription",
                            amount: 10000, // TODO: what is this???!
                            currency: "gbp",
                            // Fully customizable with appearance API.
                            // appearance: {/*...*/},
                          }}
                        >
                          <CheckoutForm subscriptionType={subscriptionType} />
                        </Elements>
                      )}
                    </div>
                  </form>
                </div>

                <div className="key-features-col">
                  <h3 className="key-features-title">Key Features</h3>

                  <div className="key-features-list-wrapper">
                    <ul>
                      <li>
                        <Checkmark />
                        Simplified and streamlined revision
                      </li>
                      <li>
                        <Checkmark />
                        Test yourself on-demand
                      </li>
                      <li>
                        <Checkmark />
                        Immediate answer correction and explanations
                      </li>
                      <li>
                        <Checkmark />
                        Cover popular exam boards like AQA and Edexcel
                      </li>
                      <li>
                        <Checkmark />
                        4000+ hand-crafted questions by professionals
                      </li>
                    </ul>
                  </div>

                  <img
                    src={stripePaymentIllustration}
                    className="stripe-payment-illustration"
                    alt=""
                  />
                  <img
                    src={poweredByStripe}
                    alt="Powered by Stripe.com"
                    id="powered-by-stripe"
                  />
                  <p id="please-contact-us">
                    Please contact{" "}
                    <Link to={"/contact-us"}>ali.hejazi@masterq.co.uk</Link> for any
                    support
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SignUpPage;
