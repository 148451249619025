import { BsPencilFill, BsPlusCircle } from "react-icons/bs";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import "./CreateAssignmentPage.scss";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { Assignment, Class, Module } from "../../types";
import { useState, useEffect, ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosClient from "../../axiosClient";
import { useUser } from "../../context/UserContext";
import ModuleSelector from "./ModuleSelector/ModuleSelector";
import BootstrapButton from 'react-bootstrap/Button';
import { ImCheckmark, ImCross } from "react-icons/im";
import toast from "react-hot-toast";

const CreateAssignmentPage = () => {
  // const iconSize = 15;
  // const iconColour = '#323232';
  // const style = {
  //     padding: "10px",
  //     backgroundColor: "white",
  //     boxShadow: '0 1px 10px rgb(0 0 0 / 0.1)',
  //     borderRadius: '20px',
  //     marginLeft: '5px'
  // };
  const { user } = useUser()
  const teacherId = user?.teacher?.id
  const [classes, setClasses] = useState<Class[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [moduleSelectors, setModuleSelectors] = useState<ReactElement[]>([]);
  const [selectedModules, setSelectedModules] = useState<{
    [moduleId: number]: number;
  }>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const subjectId = searchParams.get('subjectId');
    const getClasses = async () => {
      if (user?.type === 'teacher') {
        const classes: Class[] = (await axiosClient.get(`/teachers/${teacherId}/classes`)).data;
        setClasses(classes);
        const modules: Module[] = (
          await axiosClient.get(`/subjects/${classes[0].subjectId}/modules`)
        ).data.modules;
        setModules(modules);
      }

      else {
        const modules: Module[] = (
          await axiosClient.get(`/subjects/${subjectId}/modules`)
        ).data.modules;
        setModules(modules);

        setModuleSelectors([<ModuleSelector modules={modules} onSelectModuleQuestions={onSelectModuleQuestions}/>])
      }

      setIsLoading(false);
    };

    try {
      getClasses();
    } catch (err) {
      toast.error("Error loading page. Please seek support");
      console.error(err);
    }

  }, []);


  useEffect(() => {
    console.log(selectedModules)
  }, [selectedModules]);


  const onSelectModuleQuestions = (module: Module, numberOfQuestions: number) => {
    setSelectedModules({
      ...selectedModules,
      [module.id]: numberOfQuestions,
    });
  }

  const classOptions = classes.map((c) => {
    return {
      value: c.classId,
      name: c.className,
    };
  });

  const timeAllowedOptions = [
    {
      value: 5,
      name: "5 minutes",
    },
    {
      value: 10,
      name: "10 minutes",
    },
    {
      value: 15,
      name: "15 minutes",
    },
    {
      value: 30,
      name: "30 minutes",
    },
    {
      value: 45,
      name: "45 minutes",
    },
    {
      value: 60,
      name: "1 hour",
    },
    {
      value: 75,
      name: "1 hour and 15 minutes",
    },
    {
      value: 90,
      name: "1 hour and 30 minutes",
    },
    {
      value: 105,
      name: "1 hour and 45 minutes",
    },
    {
      value: 120,
      name: "2 hours",
    },
  ];

  const onClassChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const classId = Number(e.target.value);
      const subjectId = classes.filter((c) => c.classId === classId)[0]
        .subjectId;
      const modules: Module[] = (
        await axiosClient.get(`/subjects/${subjectId}/modules`)
      ).data;
      setModules(modules);

      // reset/clear all values
      document
        .querySelectorAll(".numberOfQuestionsSelector")
        .forEach((inputEl) => {
          (inputEl as HTMLInputElement).value = "0";
        });
    } catch (err) {
      toast.error("Error occured whilst loading modules. Please seek support");
      console.error(err);
    }
  };

  return (
    <div className="page-wrapper" id="CreateAssignmentPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title={user?.type === 'teacher' ? 'Create Assignment' : 'Timed Practice'} />
        <main>
          {isLoading ? (
            <p>Loading....</p>
          ) : (
            <form
              action=""
              onSubmit={async (e: any) => {
                e.preventDefault();
                const testExam: {
                  assignment: Assignment,
                  submission: {
                    id: number;
                    submittedDate: Date | null;
                    assignmentId: number;
                    studentId: number;
                    score: number | null;
                  }
                } = (await axiosClient.post(`/assignments-practice`, {
                    subjectId: searchParams.get('subjectId'),
                    selectedModules,
                    examDuration: e.target.timeAllowed.value
                })).data;

                navigate('/assignment', {
                  state: {
                    submissionId: testExam.submission.id,
                    assignmentId: testExam.assignment.id,
                    assignmentName: testExam.assignment.name
                  }
                })
              }}
            >
              <div className="white-box">
                <div className="exam-details">

                  {user?.type === 'teacher' && (
                    <>
                    <Input
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Assignment name"
                    />
                    <Input label="Exam due date" name="examDueDate" type="date" />

                    <Input
                        label="Class"
                        type="select"
                        name="class"
                        options={classOptions}
                        onChange={onClassChange}
                      />

                    </>
                  )}

                  <Input
                    label="Time allowed"
                    type="select"
                    name="timeAllowed"
                    options={timeAllowedOptions}
                  />

                  {user?.type === 'teacher' && (
                    <Input
                      label="Pass mark (%)"
                      name="passMark"
                      type="number"
                      placeholder="Pass mark (%)"
                    />
                  )}

                </div>

                <div className="topicQuestionAllocation">
                  <div className="titles">
                    <div>Modules</div>
                    {/* <div>Questions available</div> */}
                    <div>Number of questions to be tested on</div>
                  </div>
                  <div className="allocations" id="moduleAllocations">
                    {moduleSelectors}

                  </div>
                </div>

                <BootstrapButton onClick={(e) => {
                  e.preventDefault();
                  setModuleSelectors([
                    ...moduleSelectors,
                    <ModuleSelector modules={modules} onSelectModuleQuestions={onSelectModuleQuestions}/>
                  ])
                }} variant="primary"
                >
                  <BsPlusCircle style={{
                    marginRight: '5px'
                  }}/>
                  Add module</BootstrapButton>


                <div className="buttons">
                  <BootstrapButton variant="danger" onClick={() => {
                    navigate('/dashboard');
                  }}>
                  <ImCross color="red" size={18} style={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            padding: '5px',
                            marginRight: '5px'
                        }}/>
                  Cancel</BootstrapButton>

                  <BootstrapButton variant="success" type="submit" style={{
                    marginLeft: '10px'
                  }}> <ImCheckmark color="green" size={18} style={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    padding: '5px',
                    marginRight: '5px'
                }} /> Create</BootstrapButton>
                </div>
              </div>
            </form>
          )}
        </main>
      </div>
    </div>
  );
};

export default CreateAssignmentPage;
