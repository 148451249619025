import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useRef, useState } from "react";
import axiosClient from "../../axiosClient";
import { useNavigate } from "react-router";
import { Assignment, Module } from "../../types";
import { Table } from "react-bootstrap";

function TestExamModal(props: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  subjectId: number;
  selectedModules: Module[];
  allModulesSelected: boolean;
  subjectTotalQuestions: number;
}) {
  const handleClose = () => props.setShowModal(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const [secondsPerQuestion, setSecondsPerQuestion] = useState<number>(0);
  const [selectedModulesToNumberOfQuestionsMapper, setSelectedModulesToNumberOfQuestionsMapper] = useState<{
    [moduleId: string]: number;
  }>({});


  let totalNumberOfQuestions = 0;
  Object.keys(selectedModulesToNumberOfQuestionsMapper).forEach((key) => {
    totalNumberOfQuestions += selectedModulesToNumberOfQuestionsMapper[key];
  });

  const formatMinutesToTime = (minutes: number) => {
    const mins = Math.floor(minutes); // Get the whole minutes
    const secs = Math.round((minutes - mins) * 60); // Get the remaining seconds
    return `${mins} min and ${secs} seconds`;
  }

  let moduleRows;

  if (props.allModulesSelected) {
    moduleRows = (
      <tr key={0}>
      <td
        style={{
          display: "grid",
        }}
      >
        <p
          className="small"
          style={{
            margin: "auto",
          }}
        >
          All modules
        </p>
      </td>
  
      <td
        style={{
          padding: "0",
        }}
      >
        <input
          type="number"
          name={`module-all`}
          id={`module-all`}
          className="small"
          required
          placeholder={`Type number (0 - ${props.subjectTotalQuestions})`}
          min={0}
          max={props.subjectTotalQuestions}
          onChange={(e) => {
            setSelectedModulesToNumberOfQuestionsMapper({
              'module-all': Number(e.target.value),
            });
          }}
          style={{
            width: "100%",
            backgroundColor: "rgb(247, 247, 183)",
            border: "none",
            height: "30px",
            textAlign: "center",
          }}
        />
      </td>
    </tr>
    );
  }

  else {
    moduleRows = (
      props.selectedModules.map((module, index) => (
        <tr key={index}>
          <td
            style={{
              display: "grid",
            }}
          >
            <p
              className="small"
              style={{
                margin: "auto",
              }}
            >
              {module.name}
            </p>
          </td>
  
          <td
            style={{
              padding: "0",
            }}
          >
            <input
              type="number"
              name={`module-${module.id}`}
              id={`module-${module.id}`}
              className="small"
              required
              placeholder={`Type number (0 - ${module.numberOfQuestions})`}
              min={0}
              max={module.numberOfQuestions}
              onChange={(e) => {
                setSelectedModulesToNumberOfQuestionsMapper({
                  ...selectedModulesToNumberOfQuestionsMapper,
                  [module.id]: Number(e.target.value),
                });
              }}
              style={{
                width: "100%",
                backgroundColor: "rgb(247, 247, 183)",
                border: "none",
                height: "30px",
                textAlign: "center",
              }}
            />
          </td>
        </tr>
      ))
    )
  }

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} centered>
        <Modal.Header closeButton closeLabel="close modal">
          <Modal.Title
            style={{
              fontSize: "1rem",
            }}
          >
            Test exam details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            id="my-form"
            onSubmit={async (e) => {
              e.preventDefault();
              // const formData = Object.fromEntries(
              //   new FormData(e.target as HTMLFormElement),
              // );

              const testExam: {
                assignment: Assignment;
                submission: {
                  id: number;
                  submittedDate: Date | null;
                  assignmentId: number;
                  studentId: number;
                  score: number | null;
                };
              } = (
                await axiosClient.post(`/assignments-practice`, {
                  subjectId: props.subjectId,
                  selectedModules: selectedModulesToNumberOfQuestionsMapper,
                  secondsPerQuestion: secondsPerQuestion,
                })
              ).data;

              navigate("/assignment", {
                state: {
                  submissionId: testExam.submission.id,
                  assignmentId: testExam.assignment.id,
                  assignmentName: testExam.assignment.name,
                },
              });
            }}
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                className="small"
                style={{
                  marginBottom: "30px",
                }}
              >
                Choose the number of questions to do from each selected module
              </Form.Label>

              <Table striped="none" bordered hover size="sm" variant="">
                <thead>
                  <tr>
                    <th className="small">Subject</th>
                    <th className="small">Num of Questions</th>
                  </tr>
                </thead>
                <tbody>
                  {moduleRows}
                </tbody>
              </Table>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="small">
                <strong>Test exam duration</strong>
              </Form.Label>
              <Form.Select
                aria-label="Test exam duration"
                name="secondsPerQuestion"
                required
                style={{
                  fontSize: "0.875rem",
                  backgroundColor: "#f7f7b7",
                }}
                onChange={(e) => {
                  setSecondsPerQuestion(Number(e.target.value));
                }}
              >
                <option value="">Select duration per question</option>
                <option value="10">10 seconds per question</option>
                <option value="15">15 seconds per question</option>
                <option value="20">20 seconds per question</option>
                <option value="25">25 seconds per question</option>
                <option value="30">30 seconds per question</option>
                <option value="35">35 seconds per question</option>
                <option value="40">40 seconds per question</option>
                <option value="45">45 seconds per question</option>
                <option value="50">50 seconds per question</option>
                <option value="55">55 seconds per question</option>
                <option value="60">1 min per question</option>
                <option value="90">1.5 mins per question</option>
                <option value="120">2 mins per question</option>
              </Form.Select>
            </Form.Group>
          </Form>

          {secondsPerQuestion > 0 && totalNumberOfQuestions > 0 && (
            <p className="small"><strong>Total exam duration: {formatMinutesToTime((secondsPerQuestion * totalNumberOfQuestions) / 60)}</strong></p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" size="sm" onClick={handleClose}>
            Close
          </Button>
          <Button variant="dark" size="sm" form="my-form" type="submit">
            Start practice exam
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TestExamModal;
