import { useEffect, useState } from "react";
import { Module } from "../../../types";

const ModuleSelector = (props: {
    modules: Module[]
    onSelectModuleQuestions: (module: Module, numberOfQuestions: number) => void;
}) => {
    const [selectedModule, setSelectedModule] = useState<Module>();
    const [numberOfQuestionsToComplete, setNumberOfQuestionsToComplete] = useState<number>();

  return (
    <div
    className="allocation-wrapper"
    onClick={() => {}}
  >
    <select className="topicNameInput cell-item" name="" id="" onChange={(event) => {
      const moduleId = event.target.value;
      console.log('setting module to: ');
      console.log(props.modules.filter(m => m.id === Number(moduleId))[0]);

      if (!moduleId) {
        setSelectedModule(undefined)
      } else {
        setSelectedModule(props.modules.filter(m => m.id === Number(moduleId))[0])
      }

      // always reset number of questions to complete when module changes
      setNumberOfQuestionsToComplete(0);
    }}>
        <option value="" defaultChecked>Select module</option>
        {props.modules.map(m => (
          <option value={m.id}>{`${m.name} (${m.numberOfQuestions} questions available)`}</option>
        ))}
      </select>
    <div className="numberOfQuestionsSelectorWrapper">

      <select name=""
        className="numberOfQuestionsSelector"
        id=""
        value={numberOfQuestionsToComplete}
        onChange={(e) => {
            if (selectedModule) {
              setNumberOfQuestionsToComplete(Number(e.target.value))
              props.onSelectModuleQuestions(selectedModule, Number(e.target.value));
            }
        }}
        disabled={!selectedModule}
       >
        <option value="">Select number</option>
        <option value="1">1</option>  
        <option value="2">2</option>  
        <option value="3">3</option>  
        <option value="4">4</option>  
        <option value="5">5</option>  
        <option value="6">6</option>  
        <option value="7">7</option>  
        <option value="8">8</option>  
        <option value="9">9</option>  
      </select>  
      {/* <BsPencilFill size={12} color="#7f7f7f" /> */}
    </div>
  </div>
  )
};

export default ModuleSelector;
