import {
  BsPencilFill,
  BsArrowLeft,
  BsArrowBarLeft,
  BsArrowLeftShort,
  BsArrowLeftSquareFill,
  BsArrowRightSquareFill,
  BsArrowRightCircleFill,
} from "react-icons/bs";
import { Assignment, Question } from "../../types";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import { Button } from "react-bootstrap";

const AssignmentPageSidebar = (props: {
  currentQuestionIndex: number;
  setCurrentQuestionIndex: (index: number) => void;
  selectedAnswers: {
    [key: number]: number;
  };
  questions: Question[];
  assignment: Assignment | undefined;
  submit: () => void;
}) => {
  return (
    <>
      <div className="questions-progress-tracker">
        {props.assignment?.duration && (
          <div className="sticky-top-part">
            <CountdownTimer
              submit={props.submit}
              targetDateInMilliseconds={
                Number(
                  localStorage.getItem(
                    `master_q_assignment_${props.assignment.id}_start_time`,
                  ),
                ) +
                props.assignment.duration * 60 * 1000
              }
            />
          </div>
        )}
        <div className="past-questions-tracker">
          <ul>
            {props.questions.map((question, index) => {
              const questionAnswered = !!props.selectedAnswers[question.id];

              return (
                <li id={`sidebar-question-${index}`}>
                  <Button
                    size="sm"
                    id={`q${index + 1}`}
                    variant={
                      /* index === props.currentQuestionIndex ? 'warning' : */ questionAnswered
                        ? "dark"
                        : "light"
                    }
                    style={{
                      fontWeight:
                        index === props.currentQuestionIndex
                          ? "bold"
                          : "normal",
                      fontSize:
                        index === props.currentQuestionIndex
                          ? "larger"
                          : "small",
                      transition: "all 0.3s ease",
                      boxShadow:
                        index === props.currentQuestionIndex
                          ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                          : undefined,
                    }}
                    onClick={(e) => {
                      props.setCurrentQuestionIndex(index);
                    }}
                  >
                    {index === props.currentQuestionIndex ? (
                      <BsArrowRightCircleFill
                        size={14}
                        style={{
                          marginRight: "5px",
                        }}
                      />
                    ) : (
                      <BsPencilFill size={10} style={{ marginRight: "5px" }} />
                    )}
                    Q{index + 1}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AssignmentPageSidebar;
