import Sidebar from "../../components/Sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useEffect, useState } from "react";
// NOTE: uses scss from AssignmentPage component
import "../AssignmentPage/AssignmentPage.scss";
import axiosClient from "../../axiosClient";
import { Question } from "../../types";
import { ZoomImage } from "../../components/ZoomImage/ZoomImage";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import PracticePageSidebar from "./PracticePageSidebar";
import { AssignmentPageTitle } from "../AssignmentPage/AssignmentPageTitle";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";

const PracticePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [submittedQuestionIds, setSubmittedQuestionIds] = useState<Set<number>>(new Set());
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  // selectedAnswers maps each questionId to the answerId chosen
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: number]: number;
  }>({});

  // only used in practice mode
  const [practiceQuestionAnswered, setPracticeQuestionAnswered] =
    useState<boolean>(false);
  const [
    numberOfRemainingPracticeQuestionsInModule,
    setnumberOfRemainingPracticeQuestionsInModule,
  ] = useState<number>(0);
  const [practiceQuestionsAnsweredCount, setPracticeQuestionsAnsweredCount] =
    useState(0);
  const [showLatestQuestionInSidebar, setShowLatestQuestionInSidebar] =
    useState<boolean>(true);
  const navigate = useNavigate();
  const data = useLocation();

  const getAssignmentTitle = () => data?.state?.assignmentName;
  const getPracticeModuleIds = () => data?.state?.practiceModuleIds;
  const getPracticeSubjectId = () => data?.state?.practiceSubjectId;
  const getExamBoards = (): string[] => data?.state?.examBoards;
  const [highestQuestionNumberSeen, setHighestQuestionNumberSeen] = useState<number>(1);


  const questionNumber =
    currentQuestionIndex + 1;

  useEffect(() => {

    if (questions.length === 0) {
      return;
    }

    setPracticeQuestionAnswered(
      selectedAnswers[questions[currentQuestionIndex].id] !== undefined && submittedQuestionIds.has(questions[currentQuestionIndex].id)
    );
    const element = document.getElementById(`q${questionNumber}`);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });

    setHighestQuestionNumberSeen(Math.max(highestQuestionNumberSeen, currentQuestionIndex + 1));

  }, [currentQuestionIndex]);

  // TODO: cover use case of all questions practiced and the student gets initial questions again.
  const getNextBatchOfPracticeQuestions = async () => {
    setIsLoading(true);
    let queryParam =
      getPracticeModuleIds().length > 0
        ? `?moduleIds=${getPracticeModuleIds().join(",")}`
        : `?subjectId=${getPracticeSubjectId()}`;

    const examBoards = getExamBoards();
    if (examBoards.length > 0) {
      queryParam += `&examBoards=${examBoards.join(",")}`;
    }

    const skippedQuestions = questions.filter(
      (q) => !(q.id in selectedAnswers),
    );

    if (skippedQuestions.length > 0) {
      queryParam += `&exclude=${skippedQuestions.map((q) => q.id).join(",")}`;
    }

    const practiceQuestionsResponse = (
      await axiosClient.get(`/questions${queryParam}`)
    ).data;
    const practiceQuestions: Question[] = practiceQuestionsResponse.questions;

    // on first call (page load), set the total number of remaining questions in the module
    if (practiceQuestions.length > 0 && !numberOfRemainingPracticeQuestionsInModule) {
      setnumberOfRemainingPracticeQuestionsInModule(
        practiceQuestionsResponse.totalNumberOfQuestions - practiceQuestionsResponse.answeredQuestionsCount,
      );
    }

    setQuestions(questions.concat(practiceQuestions));

    setIsLoading(false);
  };

  useEffect(() => {
    const getQuestions = async () => {
      try {
        await getNextBatchOfPracticeQuestions();
        setIsLoading(false)
      } catch (err) {
        toast.error("Error loading assignment. Please seek support!");
        console.error(err);
      }
    };
    getQuestions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && questions.length > 0) {
      // clear all checkboxes
      document
        .querySelectorAll('input[type="checkbox"]')
        .forEach((checkbox) => {
          (checkbox as HTMLInputElement).checked = false;
        });

      document.querySelectorAll(".answer-option").forEach((answerOption) => {
        (answerOption as HTMLDivElement).classList.remove(
          "selected-answer-option",
        );
      });

      let currentQuestion = getCurrentQuestion();
      // if navigating to a question already answered, re-check the necessary checkbox
      if (currentQuestion.id in selectedAnswers) {
        const selectedAnswerId = selectedAnswers[currentQuestion.id];
        const selectedAnswer = document.getElementById(
          selectedAnswerId.toString(),
        );
        const checkbox = selectedAnswer?.querySelector(
          'input[type="checkbox"]',
        );

        if (checkbox) {
          selectedAnswer?.classList.add("selected-answer-option");
          (checkbox as HTMLInputElement).checked = true;
        }
      }
    }
  });

  if (isLoading) {
    return (
      <div className="page-wrapper" id="PracticePage">
        <Sidebar />
        <div className="internal-page-wrapper">Loading...</div>
      </div>
    );
  }

  const nextQuestion = async () => {
    if (currentQuestionIndex === questions.length - 1) {
      await getNextBatchOfPracticeQuestions();
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };


  const handleAnswerSelected = (e: any) => {
    const userClickedOnSurroundingDivToZoomOutOfImage = e.target.hasAttribute(
      "data-rmiz-modal-content",
    );
    // Avoid clicks on the image from changing the checked value, because clicking on the image
    // is done to enlarge it, not necessarily to select that answer.
    // ALSO avoid clicks on the div surrounding the enlarged image from changing the checked value.
    if (
      practiceQuestionAnswered ||
      e.target.tagName === "IMG" ||
      userClickedOnSurroundingDivToZoomOutOfImage
    ) {
      return;
    }

    // See https://stackoverflow.com/a/53815609 for why we use e.currentTarget instead of e.target
    const selectedCard = e.currentTarget as Element;

    selectedCard.parentElement
      ?.querySelectorAll(".answer-option")
      .forEach((answerOption) => {
        (answerOption as HTMLDivElement).classList.remove(
          "selected-answer-option",
        );
      });

    const checkboxInsideCard = selectedCard.querySelector(
      'input[type="checkbox"]',
    ) as HTMLInputElement;

    selectedCard.parentElement
      ?.querySelectorAll('input[type="checkbox"]')
      .forEach((checkbox) => {
        if (checkbox !== checkboxInsideCard) {
          (checkbox as HTMLInputElement).checked = false;
        }
      });

    // Only force the checkbox to change value when clicking on the card (not the checkbox itself),
    // because when the checkbox itself is clicked it auto changes value, which means the statement
    // below causes the click on the checkbox to cause no change at all, which is confusing.
    if (e.target.tagName !== "INPUT") {
      checkboxInsideCard.checked = !checkboxInsideCard.checked;
    }

    if (checkboxInsideCard.checked) {
      selectedCard.classList.add("selected-answer-option");
      const updatedAnswers = {
        ...selectedAnswers,
      };
      updatedAnswers[currentQuestion.id] = Number(selectedCard.id);
      setSelectedAnswers(updatedAnswers);
    } else {
      const updatedAnswers = {
        ...selectedAnswers,
      };
      delete updatedAnswers[currentQuestion.id];
      setSelectedAnswers(updatedAnswers);
    }
  };

  // TODO: rename this method
  const submitPracticeModeSingleQuestion = async () => {
    const questionToSubmit = questions[currentQuestionIndex];
    const selectedAnswerInPracticeMode = selectedAnswers[questionToSubmit.id];

    if (!selectedAnswerInPracticeMode) {
      toast.error("Please select an answer!");
      return;
    }

    // MAKE CALLOUT HERE - submission id should be auto-set as null in the backend. TODO: double-check this??
    const payload = {
      questionId: questionToSubmit.id,
      answerOptionId: selectedAnswerInPracticeMode,
    };

    await axiosClient.post("/question-submit", payload);

    questionToSubmit.answerOptions.forEach((answerOption) => {
      answerOption.selected =
        answerOption.id === selectedAnswerInPracticeMode;
    });

    const updatedQuestions = questions.map((q) => (q.id === questionToSubmit.id ? questionToSubmit : q));

    console.log('setting questions to: ')
    console.log(updatedQuestions)
    setQuestions(updatedQuestions);

    setPracticeQuestionAnswered(true);
    setSubmittedQuestionIds(submittedQuestionIds.add(questionToSubmit.id));
  };

  if (
    practiceQuestionsAnsweredCount ===
      numberOfRemainingPracticeQuestionsInModule &&
    currentQuestionIndex === 0
  ) {
    return (
      <div className="page-wrapper" id="PracticePage">
        <Sidebar />
        <div className="internal-page-wrapper">
          {/* <Topbar title={getAssignmentTitle()} /> */}
          <main>
            <div className="inner-main-wrapper row">
              <div className="inner-max-width-wrapper col-10">
                <p>
                  Well done, module complete 🎉🎉! You've finished all this
                  module's questions. Restart?
                </p>
              </div>
              <div className="col-2">
                {
                  <PracticePageSidebar
                    currentQuestionIndex={currentQuestionIndex}
                    maxSeenQuestionIndex={highestQuestionNumberSeen - 1}
                    questions={questions}
                    selectedAnswers={selectedAnswers}
                    submittedQuestionIds={submittedQuestionIds}
                    onQuestionClicked={setCurrentQuestionIndex}
                  />
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  const getCurrentQuestion = (): Question => {
    return questions[currentQuestionIndex];
  };

  const ABCDE = "ABCDE";

  let currentQuestion: Question = getCurrentQuestion();

  const answeredCorrectly =
    currentQuestion.answerOptions.filter(
      (answerOption) => answerOption.isCorrect && answerOption.selected,
    ).length === 1;

  const delimeters = [
    {
      // NOTE: `display` is by default `true` for the '$$' delimeter option
      // out the box from the react-latex-next package. However, I've set it
      // to `false` to ensure it doesn't render the equation in the centre
      // of the parent. Instead, `false` ensures the equation is rendered
      // in-line with the text. I should also point out that I could have just
      // used the single '$' delimeter option, where all questions with equations
      // have the equation wrapped with a single '$' either side, but I thought
      // this was risky if by accident a question has a $ sign in the question
      // text itself. In such a case, we might not realise, and the question
      // would render incorrectly. Thus, I have opted for the '$$' approach,
      // despite it meaning that we have to manually define the delimeters in
      // our implementation (which wouldn't have been the case otherwise).
      // See: https://www.npmjs.com/package/react-latex-next
      display: false,
      left: "$$",
      right: "$$",
    },
  ];

  const explanationLines = currentQuestion.explanation
    ?.split("\\n")
    .map((line) => (
      <p className="explanation-line">
        <Latex delimiters={delimeters}>{line}</Latex>
      </p>
    ));

  // TODO: rename footer component
  return (
    <div className="page-wrapper" id="PracticePage">
      <Sidebar />
      <div className="internal-page-wrapper">
        {/* <Topbar title={getAssignmentTitle()} /> */}
        <main>
          <div className="inner-main-wrapper row">
            <div
              className="inner-max-width-wrapper col-10"
              style={{
                position: "sticky",
                // padding: 0,
              }}
            >
              <div className="top-area">
                {/* rename this component - maybe QuestionMetadata? */}
                <AssignmentPageTitle
                  questionId={currentQuestion.id}
                  questionNumber={questionNumber}
                  totalQuestions={numberOfRemainingPracticeQuestionsInModule}
                  isAssignmentMode={false}
                  onNextQuestionClicked={nextQuestion}
                  nextQuestionButtonDisabled={
                    questionNumber ===
                    numberOfRemainingPracticeQuestionsInModule
                  }
                  currentQuestionIndex={currentQuestionIndex}
                  onPreviousQuestionClicked={() => {
                    setCurrentQuestionIndex(currentQuestionIndex - 1);
                  }}
                  prevQuestionButtonDisabled={questionNumber === 1}
                  //   onSkipQuestionClicked={skipPracticeModeSingleQuestion}
                />
              </div>
              <div id="scroller">
                <h5 className="questionTitle">
                  <Latex delimiters={delimeters}>{currentQuestion.text}</Latex>
                </h5>
                {currentQuestion.imageUrl && (
                  <div
                    style={{
                      marginBottom: "50px",
                    }}
                  >
                    <ZoomImage
                      src={currentQuestion.imageUrl}
                      width={300}
                    ></ZoomImage>
                  </div>
                )}

                {/* <div className='questionImage'></div> */}

                <div className="answer-options-wrapper">
                  {currentQuestion?.answerOptions.map((answerOption, index) => {
                    const option = ABCDE[index];
                    const classes = ["answer-option"];
                    if (practiceQuestionAnswered) {
                      classes.push("locked");

                      if (answerOption.selected) {
                        // gives it the blue outline
                        classes.push("selected-answer-option");
                      }
                    }
                    return (
                      <div
                        className={classes.join(" ")}
                        onClick={handleAnswerSelected}
                        id={answerOption.id.toString()}
                      >
                        <div>
                          <div className="top-part">
                            <label htmlFor={option}>{option}</label>

                            {practiceQuestionAnswered &&
                              answerOption.isCorrect && (
                                <IoIosCheckmarkCircle color="green" size={25} />
                              )}
                            {practiceQuestionAnswered &&
                              !answerOption.isCorrect && (
                                <ImCross
                                  color="white"
                                  size={20}
                                  style={{
                                    backgroundColor: "red",
                                    borderRadius: "10px",
                                    padding: "5px",
                                  }}
                                />
                              )}

                            <input
                              type="checkbox"
                              disabled={practiceQuestionAnswered}
                              name={option}
                              id={option}
                              style={{
                                display: practiceQuestionAnswered
                                  ? "none"
                                  : "block",
                              }}
                            />
                          </div>
                          <hr />
                          <div className="bottom-part">
                            <p>
                              <Latex delimiters={delimeters}>
                                {answerOption.text}
                              </Latex>
                            </p>
                            {answerOption.imageUrl && (
                              <ZoomImage
                                src={answerOption.imageUrl}
                                width={200}
                              ></ZoomImage>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {practiceQuestionAnswered ? (
                  <div
                    className={
                      answeredCorrectly
                        ? "explanation explanation-correct"
                        : "explanation explanation-incorrect"
                    }
                  >
                    <div className="explanation-title">
                      {answeredCorrectly ? (
                        <span>
                          Correct!{" "}
                          <IoIosCheckmarkCircle
                            color="green"
                            size={25}
                            style={{
                              position: "relative",
                              bottom: "2px",
                            }}
                          />
                        </span>
                      ) : (
                        <span>
                          Incorrect!{" "}
                          <ImCross
                            color="white"
                            size={20}
                            style={{
                              backgroundColor: "red",
                              borderRadius: "10px",
                              padding: "5px",
                              position: "relative",
                              bottom: "2px",
                            }}
                          />
                        </span>
                      )}
                    </div>
                    {explanationLines}
                    <div
                      style={{
                        display: "flex",
                        gap: 20,
                        marginTop: 20,
                      }}
                    >
                      { currentQuestion.explanationImageUrl ? <ZoomImage src={currentQuestion.explanationImageUrl} width={150}></ZoomImage> : undefined}
                    </div>
                  </div>
                ) : undefined}

                <Button
                  style={{
                    display: practiceQuestionAnswered ? "none" : "block",
                  }}
                  onClick={submitPracticeModeSingleQuestion}
                >
                  Submit answer
                </Button>

                <Button
                  style={{
                    float: "right",
                    display: practiceQuestionAnswered ? "block" : "none",
                  }}
                  onClick={nextQuestion}
                >
                  Next question
                </Button>
                {/* <Editor initialContent={currentQuestion.explanation?.text} /> */}
              </div>
            </div>
            <div className="col-2">
              {
                // <PracticePageSidebar
                //   submittedPracticeQuestions={[]}
                //   onSubmittedQuestionClicked={(questionNumber: number) => {
                //     setCurrentQuestionIndex(
                //       questionNumber - 1
                //     );
                //   }}
                //   latestQuestion={questions[0]}
                //   showLatestQuestion={showLatestQuestionInSidebar}
                //   currentQuestionIndex={currentQuestionIndex}
                //   currentQuestionNumber={questionNumber}
                // />

                <PracticePageSidebar
                  currentQuestionIndex={currentQuestionIndex}
                  maxSeenQuestionIndex={highestQuestionNumberSeen - 1}
                  questions={questions}
                  selectedAnswers={selectedAnswers}
                  submittedQuestionIds={submittedQuestionIds}
                  onQuestionClicked={setCurrentQuestionIndex}
                />
              }
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PracticePage;
