import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import "./AddTeachersPage.scss";
import Button from "../../components/Button/Button";
import { SimpleTeacher } from "../../types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component/dist/src/DataTable/types";
import Table from "../../components/Table/Table";
import DragAndDropFileUploader from "../../components/DragAndDropFileUploader/DragAndDropFileUploader";
import axiosClient from "../../axiosClient";
import toast from "react-hot-toast";

const AddTeachersPage = (props: any) => {
  // drag state
  const [teachers, setTeachers] = useState<SimpleTeacher[]>([]);
  const navigate = useNavigate();

  const columns: TableColumn<any>[] = [
    {
      id: "title",
      name: "Title",
      selector: (row: any) => row.title,
    },
    {
      id: "firstName",
      name: "First Name",
      selector: (row: any) => row.firstName,
    },
    {
      id: "surname",
      name: "Surname",
      selector: (row: any) => row.surname,
    },
    {
      id: "isAdmin",
      name: "Is Admin",
      selector: (row: any) => row.isAdmin,
    },
    {
      id: "class",
      name: "Class",
      selector: (row: any) => row.class,
    },
  ];

  const onCancelButtonClick = () => {
    setTeachers([]);
  };

  const onConfirmButtonClick = async () => {
    try {
      await axiosClient.post("/teachers", teachers);
      toast.success("Success!");
      navigate("/dashboard");
    } catch (err) {
      toast.error("Error occurred when persiting teachers. Please contact support.");
      console.error(err);
      navigate("/");
    }
  };

  return (
    <div className="page-wrapper" id="AddTeachersPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title="Add Teachers" />
        <main>
          <div className="white-box">
            {teachers.length > 0 ? (
              <>
                <div id="review-teachers-header-wrapper">
                  <h2>Review teachers</h2>
                  <div id="review-teachers-button-wrapper">
                    <Button
                      text="Cancel"
                      type="inverse"
                      onClick={onCancelButtonClick}
                    />
                    <Button text="Confirm" onClick={onConfirmButtonClick} />
                  </div>
                </div>

                <p>
                  Please review the teachers to be added, and if happy, please
                  click on the 'Confirm' button. If you are unhappy, please
                  click on 'Cancel' to try again.
                </p>

                <Table columns={columns} data={teachers} />
              </>
            ) : (
              <>
                <h2>Teachers csv file upload</h2>
                <p>
                  Please upload a csv file containing all teachers to be
                  enrolled in your school/academy.
                </p>
                <p>
                  Csv files must contain the following columns in the following
                  order <strong>(and match them exacty)</strong>:{" "}
                  <code>Title, First name, Surname, Is Admin, Class</code>
                </p>
                <p>
                  Please download this template csv file and then update to
                  include all your teachers.
                </p>
                <Button text="Download template.csv" type="inverse" />

                <DragAndDropFileUploader
                  accept=".csv"
                  setData={setTeachers}
                  expectedColumnTitles={[
                    "Title",
                    "First Name",
                    "Surname",
                    "Is Admin",
                    "Class",
                  ]}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddTeachersPage;
