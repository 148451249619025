import { Button } from "react-bootstrap";
import { ImCross, ImNext } from "react-icons/im";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Question } from "../../types";
import { useEffect } from "react";

const SubmissionPageSidebar = (props: {
  submittedPracticeQuestions: Question[];
  onSubmittedQuestionClicked: (index: number) => void;
  reverseQuestionNumbering?: boolean;
  // currentQuestionIndex is the question the user is viewing
  currentQuestionIndex: number;
}) => {
  const numberOfSubmittedPracticeQuestions =
    props.submittedPracticeQuestions.length;
  const numberCorrect = props.submittedPracticeQuestions.filter(
    (q) => q.answerOptions.filter((a) => a.selected && a.isCorrect).length > 0,
  ).length;
  const numberSkipped = props.submittedPracticeQuestions.filter(q => q.skipped).length;
  const numberIncorrect = numberOfSubmittedPracticeQuestions - numberCorrect - numberSkipped;
  const currentQuestionNumber = props.currentQuestionIndex + 1;

  useEffect(() => {
    const element = document.getElementById(`q${currentQuestionNumber}`);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const percentage =
    (numberOfSubmittedPracticeQuestions - numberSkipped) === 0
      ? 0
      : ((numberCorrect / (numberOfSubmittedPracticeQuestions - numberSkipped)) * 100).toFixed(0);

  return (
    <div className="questions-progress-tracker">
      <div className="sticky-top-part">
        <h5 className="mb-4">
          Correct <span style={{ float: "right" }}>{percentage}%</span>
        </h5>
        <ul>
          <li>
            <IoIosCheckmarkCircle color="green" size={20} /> Correct{" "}
            <span className="progress-total-correct">{numberCorrect}</span>
          </li>
          <li>
            <ImCross
              color="white"
              size={18}
              style={{
                backgroundColor: "red",
                borderRadius: "10px",
                padding: "5px",
              }}
            />{" "}
            Incorrect{" "}
            <span className="progress-total-incorrect">{numberIncorrect}</span>
          </li>
          {/* <li>
              <ImNext color="gret" size={18} /> Skipped <span className="progress-total-skipped">{numberSkipped}</span>
          </li> */}
        </ul>
      </div>

      <div className="past-questions-tracker">
        <ul>
          {props.submittedPracticeQuestions.map(
            (submittedPracticeQuestion, index) => {
              const questionNumber = props.reverseQuestionNumbering
                ? numberOfSubmittedPracticeQuestions - index
                : index + 1;
              const isViewingThisQuestion =
                currentQuestionNumber === questionNumber;

              if (submittedPracticeQuestion.skipped) {
                return (
                  <li>
                    <Button
                      id={`q${questionNumber}`}
                      style={{
                        fontWeight: isViewingThisQuestion ? "bold" : "normal",
                        fontSize: isViewingThisQuestion ? "larger" : "small",
                        transition: "all 0.3s ease",
                        boxShadow: isViewingThisQuestion
                          ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                          : undefined,
                      }}
                      onClick={(e) => {
                        props.onSubmittedQuestionClicked(index);
                      }}
                      size="sm"
                      variant={
                        isViewingThisQuestion ? "light" : "light"
                      }
                    >
                      <ImNext size={isViewingThisQuestion ? 16 : 14} /> Q{questionNumber}
                    </Button>
                  </li>
                );
              } else if (
                submittedPracticeQuestion.answerOptions.filter(
                  (answer) => answer.selected && answer.isCorrect,
                ).length > 0
              ) {
                return (
                  <li>
                    <Button
                      id={`q${questionNumber}`}
                      style={{
                        fontWeight: isViewingThisQuestion ? "bold" : "normal",
                        fontSize: isViewingThisQuestion ? "larger" : "small",
                        transition: "all 0.3s ease",
                        boxShadow: isViewingThisQuestion
                          ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                          : undefined,
                      }}
                      onClick={(e) => {
                        props.onSubmittedQuestionClicked(index);
                      }}
                      size="sm"
                      variant={
                        isViewingThisQuestion ? "light" : "light"
                      }
                    >
                      <IoIosCheckmarkCircle size={isViewingThisQuestion ? 18 : 16} color="green" /> Q{questionNumber}
                    </Button>
                  </li>
                );
              } else {
                return (
                  <li>
                    <Button
                      id={`q${questionNumber}`}
                      style={{
                        fontWeight: isViewingThisQuestion ? "bold" : "normal",
                        fontSize: isViewingThisQuestion ? "larger" : "small",
                        transition: "all 0.3s ease",
                        boxShadow: isViewingThisQuestion
                          ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                          : undefined,
                      }}
                      onClick={(e) => {
                        props.onSubmittedQuestionClicked(index);
                      }}
                      size="sm"
                      variant={
                        isViewingThisQuestion ? "light" : "light"
                      }
                    >
                      <ImCross
                        color="white"
                        size={isViewingThisQuestion ? 15 : 14}
                        style={{
                          backgroundColor: "red",
                          // color: isViewingThisQuestion ? "red" : "white",
                          borderRadius: "10px",
                          padding: "3px",
                          marginRight: "5px",
                        }}
                      />
                      Q{questionNumber}
                    </Button>
                  </li>
                );
              }
            },
          )}
        </ul>

        {/* <Button size="sm" variant="outline-dark">Load more...</Button> */}
      </div>
    </div>
  );
};

export default SubmissionPageSidebar;
