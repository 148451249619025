import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import "./ThreeDotsMenu.scss";
export const ThreeDotsMenu = (props: {
  idx: number;
  onClickReview: () => void;
  onClickReset: () => void;
}) => {
  return (
    <div className="ThreeDotsMenu">
      <DropdownButton
        as={ButtonGroup}
        key={props.idx}
        id={`dropdown-button-drop-${props.idx}`}
        size="sm"
        variant="light"
        title="&#8942;"
        onClick={(e: any) => e.stopPropagation()}
      >
        <Dropdown.Item
          eventKey="1"
          style={{
            fontSize: "small",
          }}
          onClick={() => {
            props.onClickReview();
          }}
        >
          Review
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          style={{
            color: "red",
            fontSize: "small",
          }}
          onClick={() => {
            props.onClickReset();
          }}
        >
          Reset progress
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};
