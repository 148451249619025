import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";
import { useEffect, useState } from "react";
// NOTE: uses scss from AssignmentPage component
import "../AssignmentPage/AssignmentPage.scss";
import axiosClient from "../../axiosClient";
import { ZoomImage } from "../../components/ZoomImage/ZoomImage";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { Loader } from "rsuite";
import { Question } from "../../types";
import { AssignmentPageTitle } from "../AssignmentPage/AssignmentPageTitle";
import SubmissionPageSidebar from "../SubmissionPage/SubmissionPageSidebar";

const ReviewPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()


  let { submissionId } = useParams();

  useEffect(() => {
    let subjectId = searchParams.get('subjectId');
    let moduleIds = searchParams.get('moduleIds');

    let queryParms = "?"
    if (subjectId) {
      queryParms += `subjectId=${subjectId}`
    }
    else {
      queryParms += `moduleIds=${moduleIds}`
    }

    const getQuestions = async () => {
      try {
        const submissionResp: {
          id: number;
          submittedDate: string;
          assignmentId: number;
          assignment: {
            name: string
          };
          studentId: number;
          score: number;
          questions: Question[];
        } = (await axiosClient.get("/practice-questions-completed" + queryParms))
            .data;
  
        const submissionAnswers = {};
        submissionResp.questions.forEach((question) => {
          let submissionAnswer;
  
          submissionAnswer = question.answerOptions.filter(
            (answerOption) => answerOption.selected,
          );
  
          if (submissionAnswer.length) {
            (submissionAnswers as any)[question.id] =
              submissionAnswer[0].id;
          }
        });
        setQuestions(submissionResp.questions);
        setIsLoading(false);
      }

      catch (error) {
        navigate('/dashboard')
      }
    }

    getQuestions();
  }, [])

  useEffect(() => {
    const element = document.getElementById(`q${currentQuestionIndex + 1}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [currentQuestionIndex]);
  
  if (isLoading) {
    return (
      <div className="page-wrapper" id="ReviewPage">
        <Sidebar />
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    );
  }

  const ABCDE = "ABCDE";
  const currentQuestion = questions[currentQuestionIndex];

  const answeredCorrectly =
    currentQuestion.answerOptions.filter(
      (answerOption) => answerOption.isCorrect && answerOption.selected,
    ).length === 1;
  const noAnswerSubmittedForThisQuestion =
    currentQuestion.answerOptions.filter(
      (answerOption) => answerOption.selected,
    ).length === 0;

  const delimeters = [
    {
      // NOTE: `display` is by default `true` for the '$$' delimeter option
      // out the box from the react-latex-next package. However, I've set it
      // to `false` to ensure it doesn't render the equation in the centre
      // of the parent. Instead, `false` ensures the equation is rendered
      // in-line with the text. I should also point out that I could have just
      // used the single '$' delimeter option, where all questions with equations
      // have the equation wrapped with a single '$' either side, but I thought
      // this was risky if by accident a question has a $ sign in the question
      // text itself. In such a case, we might not realise, and the question
      // would render incorrectly. Thus, I have opted for the '$$' approach,
      // despite it meaning that we have to manually define the delimeters in
      // our implementation (which wouldn't have been the case otherwise).
      // See: https://www.npmjs.com/package/react-latex-next
      display: false,
      left: "$$",
      right: "$$",
    },
  ];

  const explanationLines = currentQuestion.explanation
    ?.split("\\n")
    .map((line) => (
      <p className="explanation-line">
        <Latex delimiters={delimeters}>{line}</Latex>
      </p>
    ));


  const handleQuestionChange = (e: { selected: number }) => {
    const newQuestionIndex = e.selected;
    setCurrentQuestionIndex(newQuestionIndex);
  };
  

  return (
    <div className="page-wrapper" id="ReviewPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <main>
          <div className="inner-main-wrapper row">
            <div className="inner-max-width-wrapper col-10" style={{
              position: 'sticky',
            }}>
              <div className="top-area">
                <AssignmentPageTitle
                    questionId={currentQuestion.id}
                    questionNumber={currentQuestionIndex + 1}
                    totalQuestions={questions.length}
                    isAssignmentMode={false}
                    onNextQuestionClicked={() => {
                      handleQuestionChange({ selected: currentQuestionIndex + 1 });
                    }}
                    nextQuestionButtonDisabled={currentQuestionIndex === questions.length - 1}
                    currentQuestionIndex={currentQuestionIndex}
                    onPreviousQuestionClicked={() => {
                      handleQuestionChange({ selected: currentQuestionIndex - 1 });
                    }}
                    prevQuestionButtonDisabled={currentQuestionIndex === 0}
                />
              </div>


              <div id="scroller">
              { noAnswerSubmittedForThisQuestion ? (
                <p className="question-was-not-answered">
                  NOTE: You completed the assignment without answering this
                  question.
                </p>
              ) : undefined}
              <h5 className="questionTitle">
                <Latex delimiters={delimeters}>{currentQuestion.text}</Latex>
              </h5>

              { currentQuestion.imageUrl && (
                <div style={{
                  marginBottom: '50px'
                }}>
                  <ZoomImage src={currentQuestion.imageUrl} width={300}></ZoomImage>
                </div>
              ) }

              <div className="answer-options-wrapper">
                {currentQuestion?.answerOptions.map((answerOption, index) => {
                  const option = ABCDE[index];
                  const classes = ["answer-option"];
                  classes.push("locked");

                  if (answerOption.selected) {
                    // gives it the blue outline
                    classes.push('selected-answer-option');
                  }
                  return (
                    <div
                      className={classes.join(" ")}
                      id={answerOption.id.toString()}
                    >
                      <div>
                        <div className="top-part">
                          <label htmlFor={option}>{option}</label>


                          { answerOption.isCorrect && <IoIosCheckmarkCircle color="green" size={25} />}
                          { !answerOption.isCorrect && <ImCross color="white" size={20} style={{
                            backgroundColor: 'red',
                            borderRadius: '10px',
                            padding: '5px'
                          }} />}
                          

                          <input
                            type="checkbox"
                            disabled
                            name={option}
                            id={option}
                            style={{
                              display: 'none'
                            }}
                          />
                        </div>
                        <hr />
                        <div className="bottom-part">
                          <p>
                            <Latex delimiters={delimeters}>
                              {answerOption.text}
                            </Latex>
                          </p>
                          { answerOption.imageUrl && <ZoomImage src={answerOption.imageUrl} width={200}></ZoomImage> }
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className={
                  answeredCorrectly
                    ? "explanation explanation-correct"
                    : "explanation explanation-incorrect"
                }
              >
                <div className="explanation-title">
                  {answeredCorrectly ? <span>Correct! <IoIosCheckmarkCircle color="green" size={25} style={{
                    position: 'relative',
                    bottom: '2px'
                  }} /></span>: <span>Incorrect! <ImCross color="white" size={20} style={{
                          backgroundColor: 'red',
                          borderRadius: '10px',
                          padding: '5px',
                          position: 'relative',
                          bottom: '2px'
                        }} /></span>}
                </div>
                {explanationLines}
                <div style={{
                  display: 'flex',
                  gap: 20,
                  marginTop: 20
                }}>
                  { currentQuestion.explanationImageUrl ? <ZoomImage src={currentQuestion.explanationImageUrl} width={150}></ZoomImage> : undefined}
                </div>
              </div>
              </div>


            </div>
            <div className="col-2">
              <SubmissionPageSidebar
                submittedPracticeQuestions={questions}
                onSubmittedQuestionClicked={(index: number) => {
                    setCurrentQuestionIndex(index)
                }}
                currentQuestionIndex={currentQuestionIndex}
                // reverseQuestionNumbering
              />
          </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ReviewPage;
