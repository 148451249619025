import { useState } from "react";
import { HiMiniFlag } from "react-icons/hi2";
import FlagQuestionModal from "../../components/Modal/FlagQuestionModal";
import { Button as BootstrapButton, Button } from "react-bootstrap";
import { IoIosCheckmarkCircle } from "react-icons/io";
// import Button from "../../components/Button/Button";
import {
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
  BsCaretRight,
  BsCaretRightFill,
  BsFillSkipForwardCircleFill,
  BsSkipForward,
  BsSkipForwardBtnFill,
  BsSkipForwardCircle,
} from "react-icons/bs";
import { ImNext } from "react-icons/im";

export const AssignmentPageTitle = (props: {
  questionNumber: number;
  totalQuestions: number;
  questionId: number;
  isAssignmentMode: boolean;
  submitAssignment?: () => void;
  onNextQuestionClicked?: () => void;
  nextQuestionButtonDisabled?: boolean;
  onPreviousQuestionClicked?: () => void;
  prevQuestionButtonDisabled?: boolean;
  currentQuestionIndex?: number;
  // onSkipQuestionClicked?: () => void;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => setShowModal(true);
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <h4
          style={{
            margin: "auto",
          }}
        >
          Question {props.questionNumber} of {props.totalQuestions}
        </h4>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        {props.isAssignmentMode ? (
          <BootstrapButton
            variant="dark"
            size="sm"
            className="small"
            onClick={props.submitAssignment}
            style={
              {
                // fontSize: '0.9rem'
              }
            }
          >
            Submit Exam <IoIosCheckmarkCircle />
          </BootstrapButton>
        ) : undefined}

        <>
          <button
            style={{
              height: "fit-content",
              backgroundColor: "transparent",
            }}
            onClick={props.onPreviousQuestionClicked}
            disabled={props.prevQuestionButtonDisabled}
          >
            <div>
              <BsArrowLeftCircleFill
                size={22}
                style={{
                  margin: "auto 5px auto auto",
                }}
                color= {props.prevQuestionButtonDisabled ? 'grey' : "#0d6efd"} 
              />
            </div>
          </button>

          <button
            style={{
              height: "fit-content",
              backgroundColor: "transparent",
            }}
            onClick={props.onNextQuestionClicked}
            disabled={props.nextQuestionButtonDisabled}
          >
            <div>
              <BsArrowRightCircleFill
              size={22}
                style={{
                  margin: "auto auto auto 5px",
                }}
                color={props.nextQuestionButtonDisabled ? 'grey' : "#0d6efd"} 
              />
            </div>
          </button>
        </>
      </div>

      {/* <Button text="Skip question" type="inverse" /> */}
      <FlagQuestionModal
        setShowModal={setShowModal}
        showModal={showModal}
        questionId={props.questionId}
      ></FlagQuestionModal>
    </>
  );
};
