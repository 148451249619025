import ProgressBar from "@ramonak/react-progress-bar";
// import ProgressBarCircular from 'react-customizable-progressbar';
import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ModulesListPage.scss";
import Button from "../../components/Button/Button";
import { useState, useEffect, useRef } from "react";
import { ExamBoard, Module } from "../../types";
import axiosClient from "../../axiosClient";
import TestExamModal from "../../components/Modal/TestExamModal";
import { Loader } from "rsuite";
// import MultiSelectDropDown from "../../components/MultiSelectDropDown/MultiSelectDropDown";
// import { MdFilterAlt } from "react-icons/md";
import FilterQuestionsModal from "../../components/Modal/FilterQuestionsModal";
// import { Form } from "react-bootstrap";
import BootstrapButton from "react-bootstrap/Button";
import ProgressBarCicularCut from "../../components/ProgressBarCircularCut/ProgressBarCircularCut";
import {
  BsClock,
  BsClockFill,
  BsLightning,
  BsLightningChargeFill,
} from "react-icons/bs";
import toast from "react-hot-toast";
import { ThreeDotsMenu } from "../../components/ThreeDotsMenu/ThreeDotsMenu";

const ModulesListPage = (props: any) => {
  const [subjectName, setSubjectName] = useState<string>("");
  const [subjectTotalQuestions, setSubjectTotalQuestions] = useState<number>(0);
  const [totalSelectedQuestions, setTotalSelectedQuestions] =
    useState<number>(0);
  const [examBoardsSelected, setExamBoardsSelected] = useState<string[]>([]);
  const [examBoardFiltersApplied, setExamBoardFiltersApplied] =
    useState<boolean>(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [examBoards, setExamBoards] = useState<ExamBoard[]>([]);
  const [isLoadingModules, setIsLoadingModules] = useState<boolean>(true);
  const [isLoadingExamBoards, setIsLoadingExamBoards] = useState<boolean>(true);
  const [showTestExamModal, setShowTestExamModal] = useState<boolean>(false);
  const [showFilterQuestionsModal, setShowFilterQuestionsModal] =
    useState<boolean>(false);
  const [selectedModules, setSelectedModules] = useState<Module[]>([]);
  const [allModulesSelected, setAllModulesSelected] = useState<boolean>(true);

  // const handleShowFilterQuestionsModal = () => setShowFilterQuestionsModal(true);

  const navigate = useNavigate();
  let { subjectId } = useParams();

  const getModules = async (examBoards: string[] = []) => {
    setIsLoadingModules(true);

    try {
      let endpoint = `/subjects/${subjectId}/modules`;
      if (examBoards.length) {
        endpoint += `?examBoards=${examBoards.join(",")}`;
      }

      const resp: {
        subjectId: number;
        subjectName: string;
        subjectTotalQuestions: number;
        modules: Module[];
      } = (await axiosClient.get(endpoint)).data;

      setSubjectName(resp.subjectName);
      setSubjectTotalQuestions(resp.subjectTotalQuestions);
      setModules(resp.modules);
      setIsLoadingModules(false);

      if (examBoards.length) {
        setExamBoardFiltersApplied(true);
      }
    } catch (err) {
      toast.error("Error occured whilst loading modules. Please seek support!");
      console.error(err);
    }
  };

  const clearExamBoardsSelection = () => {
    const allExamBoardsCheckbox = document.getElementById(
      "All",
    ) as HTMLInputElement;
    if (allExamBoardsCheckbox) {
      allExamBoardsCheckbox.checked = true;
    }

    setExamBoardsSelected([]);
    setExamBoardFiltersApplied(false);
    // Notice the checkboxes selector below retrieves all checkboxes, except the 'All' one.
    const checkboxes = document.querySelectorAll(
      '.checkbox-wrapper input[type="checkbox"]:not(#All)',
    );
    for (let i = 0; i < checkboxes.length; i++) {
      const cb = checkboxes[i] as HTMLInputElement;
      cb.checked = false;
    }
  };

  const updateSelectedModules = (m: Module, isChecked: boolean) => {
    const numberOfUnattemptedQuestionsInModule =
      m.numberOfQuestions - m.numberOfQuestionsCompleted;
    if (isChecked) {
      setTotalSelectedQuestions(
        totalSelectedQuestions + numberOfUnattemptedQuestionsInModule,
      );
      setSelectedModules([
        ...selectedModules,
        modules.find((module) => module.id === m.id)!,
      ]);
    } else {
      if (selectedModules.length === 1) {
        clearSelectedModules();
      } else {
        setTotalSelectedQuestions(
          totalSelectedQuestions - numberOfUnattemptedQuestionsInModule,
        );
        setSelectedModules(
          selectedModules.filter((module) => module.id !== m.id),
        );
      }
    }
  };

  const clearSelectedModules = () => {
    setSelectedModules([]);
    setTotalSelectedQuestions(0);
    setAllModulesSelected(true);
  };

  useEffect(() => {
    if (!subjectId) {
      navigate("/dashboard");
    }

    const getExamBoards = async () => {
      setIsLoadingExamBoards(true);
      try {
        const resp: ExamBoard[] = (
          await axiosClient.get(`/subjects/${subjectId}/exam-boards`)
        ).data;
        setExamBoards(resp);
        setIsLoadingExamBoards(false);
      } catch (err) {
        toast.error(
          "Error occured whilst loading exam boards. Please seek support!",
        );
        console.error(err);
      }
    };

    getModules();
    getExamBoards();
    // clear exam board selections on page load - this is needed when navigating from one subject to another, because
    // without this hook the exam boards remain selected.
    clearExamBoardsSelection();

    // Ignore the ESLINT error message for the [] dependency below.
    // If I do as it suggests, it calls /subjects/id/modules infinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId]);

  const totalAttemptedQuestions = modules.reduce(
    (acc, module) => acc + module.numberOfQuestionsCompleted,
    0,
  );

  const totalCorrectQuestions = modules.reduce(
    (acc, module) => acc + module.correctlyAnsweredQuestionsCount,
    0,
  );

  const totalIncorrectQuestions = modules.reduce(
    (acc, module) => acc + module.incorrectlyAnsweredQuestionsCount,
    0,
  );

  // const clearProgress = async (type: 'module' | 'subject', id: number) => {
  //   const confirmation = window.confirm(`Are you sure you want to clear progress for this ${type}?`);

  //   if (type === 'module') {
  //     if (confirmation) {
  //       const res = await axiosClient.put(`/reset-progress?${type}Id=${id}`);
  
  //       if (res.status === 200) {
  //         toast.success(`${type} progress reset successfully`);
  
  //         setModules(modules.map(module => {
  //           if (module.id === id) {
  //             return {
  //               ...module,
  //               correctlyAnsweredQuestionsCount: 0,
  //               incorrectlyAnsweredQuestionsCount: 0,
  //               numberOfQuestionsCompleted: 0
  //             }
  //           }
  
  //           return module
  //         }))
  //       }
  
  //       else {
  //         toast.error('Error occurred reseting module progress')
  //       }
  //     }
  //   }


  // }

  let modulesSection;
  if (modules.length === 0) {
    modulesSection = <p>No modules found.</p>;
  } else {
    modulesSection = (
      <tbody>
        <tr
          className={allModulesSelected ? "selected" : undefined}
          id="module-all"
          onClick={(event) => {
            setAllModulesSelected(!allModulesSelected);
            clearSelectedModules();
          }}
        >
          <td>
            <input
              type="checkbox"
              checked={allModulesSelected}
              style={{
                width: "17px",
                height: "17px",
              }}
            ></input>
          </td>
          <td>All modules</td>
          <td>
            <p>
              {totalAttemptedQuestions} / {subjectTotalQuestions} questions
            </p>
            <ProgressBar
              completed={
                totalAttemptedQuestions
                  ? (totalAttemptedQuestions / subjectTotalQuestions) * 100
                  : 0
              }
              bgColor="#316AFF"
              height="6px"
              width="100%"
              isLabelVisible={false}
            />
          </td>
          <td>{totalCorrectQuestions}</td>
          <td>{totalIncorrectQuestions}</td>
          <td>
            <p>
              {
                <>
                  {totalAttemptedQuestions
                    ? (
                        (totalCorrectQuestions / totalAttemptedQuestions) *
                        100
                      ).toFixed(0)
                    : 0}
                  %
                </>
              }
            </p>
            <ProgressBar
              completed={
                totalAttemptedQuestions
                  ? (totalCorrectQuestions / totalAttemptedQuestions) * 100
                  : 0
              }
              bgColor="#20ee20"
              baseBgColor={true ? "#d90000" : undefined}
              height="6px"
              width="100%"
              isLabelVisible={false}
            />
          </td>
          <td>
            <div
              style={{
                float: "right",
              }}
            >
              <ThreeDotsMenu
                idx={0}
                onClickReview={() => {
                  navigate("/review?subjectId=" + subjectId);
                }}
                onClickReset={() => {
                  window.confirm('Are you sure you want to clear progress for this subject?')
                }}
              />
            </div>
          </td>
        </tr>

        {modules.map((m, index) => {
          const percentageComplete = m.correctlyAnsweredQuestionsCount
            ? (
                (m.correctlyAnsweredQuestionsCount /
                  m.numberOfQuestionsCompleted) *
                100
              ).toFixed(0)
            : 0;

          const isSelected = selectedModules.some(
            (module) => module.id === m.id,
          );

          if (m.numberOfQuestions === 0) {
            return undefined;
          }

          return (
            <tr
              className={isSelected ? "moduleRow selected" : "moduleRow"}
              id={`module-${m.id}`}
              onClick={(event) => {
                updateSelectedModules(m, !isSelected);
                setAllModulesSelected(false);
              }}
            >
              <td>
                <input
                  type="checkbox"
                  checked={isSelected}
                  style={{
                    width: "17px",
                    height: "17px",
                  }}
                ></input>
              </td>
              <td>{m.name}</td>
              <td>
                <p>
                  {m.numberOfQuestionsCompleted} / {m.numberOfQuestions}{" "}
                  questions
                </p>
                <ProgressBar
                  completed={
                    (m.numberOfQuestionsCompleted / m.numberOfQuestions) * 100
                  }
                  bgColor="#316AFF"
                  height="6px"
                  width="100%"
                  isLabelVisible={false}
                />
              </td>
              <td>{m.correctlyAnsweredQuestionsCount}</td>
              <td>{m.incorrectlyAnsweredQuestionsCount}</td>
              <td>
                {/* <p>{ m.numberOfQuestionsCompleted > 0 ? <>{percentageComplete}%</> : <>{percentageComplete}% (not started)</> }</p> */}
                <p>{<>{percentageComplete}%</>}</p>
                <ProgressBar
                  completed={
                    m.correctlyAnsweredQuestionsCount
                      ? (
                          (m.correctlyAnsweredQuestionsCount /
                            m.numberOfQuestionsCompleted) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                  bgColor="#20ee20"
                  baseBgColor={
                    m.numberOfQuestionsCompleted > 0 ? "#d90000" : undefined
                  }
                  height="6px"
                  width="100%"
                  isLabelVisible={false}
                />
              </td>
              <td>
                <div
                  style={{
                    float: "right",
                  }}
                >
                  {/* add 1 because index 0 is hard coded for the 'All modules' row */}
                  <ThreeDotsMenu
                    idx={index + 1}
                    onClickReview={() => {
                      navigate("/review?moduleIds=" + m.id);
                    }}
                    onClickReset={async () => {
                      const confirmation = window.confirm('Are you sure you want to clear progress for this module?');

                      if (confirmation) {
                        const res = await axiosClient.put(`/reset-progress?moduleId=${m.id}`);

                        if (res.status === 200) {
                          toast.success('Module progress reset successfully');

                          setModules(modules.map(module => {
                            if (module.id === m.id) {
                              return {
                                ...module,
                                correctlyAnsweredQuestionsCount: 0,
                                incorrectlyAnsweredQuestionsCount: 0,
                                numberOfQuestionsCompleted: 0
                              }
                            }

                            return module
                          }))
                        }

                        else {
                          toast.error('Error occurred reseting module progress')
                        }
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  let mainSection;
  if (isLoadingModules) {
    mainSection = (
      <div
        style={{
          display: "grid",
          height: "100%",
        }}
      >
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    );
  } else {
    mainSection = (
      <main>
        <TestExamModal
          setShowModal={setShowTestExamModal}
          allModulesSelected={allModulesSelected}
          subjectTotalQuestions={subjectTotalQuestions}
          showModal={showTestExamModal}
          subjectId={Number(subjectId)}
          selectedModules={selectedModules}
        ></TestExamModal>
        <FilterQuestionsModal
          setShowModal={setShowFilterQuestionsModal}
          showModal={showFilterQuestionsModal}
        ></FilterQuestionsModal>
        <div>
          <div className="table-top-bar">
            <h2>
              {totalSelectedQuestions ? (
                <>
                  <div id="numQuestionsSelected">{totalSelectedQuestions}</div>
                  <div id="numQuestionsSelectedText">SELECTED</div>
                </>
              ) : (
                <>Topics</>
              )}
            </h2>
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  margin: "auto",
                }}
              >
                <BootstrapButton
                  variant="primary"
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    if (selectedModules.length || allModulesSelected) {
                      setShowTestExamModal(true);
                    } else {
                      toast.error(
                        "Please select at least one module to proceed.",
                      );
                    }
                  }}
                  size="sm"
                >
                  <BsClockFill
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  Timed Practice &#8250;
                </BootstrapButton>

                <BootstrapButton
                  variant="dark"
                  onClick={() => {
                    if (selectedModules.length || allModulesSelected) {
                      navigate("/practice", {
                        state: {
                          assignmentName: "Quick Practice",
                          practiceModuleIds: selectedModules.map((m) => m.id),
                          practiceSubjectId: subjectId,
                          examBoards: examBoardFiltersApplied
                            ? examBoardsSelected
                            : [],
                        },
                      });
                    } else {
                      toast.error(
                        "Please select at least one module to proceed.",
                      );
                    }
                  }}
                  size="sm"
                >
                  <BsLightningChargeFill
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  Quick Practice &#8250;
                </BootstrapButton>
              </div>
            </div>
          </div>

          <table>
            <colgroup>
              <col span={1} style={{ width: "5%" }} />
              <col span={1} style={{ width: "22%" }} />
              <col span={1} style={{ width: "14%" }} />
              <col span={1} style={{ width: "10%" }} />
              <col span={1} style={{ width: "10%" }} />
              <col span={1} style={{ width: "14%" }} />
              <col span={1} style={{ width: "15%" }} />
            </colgroup>

            <div className="table-titles-wrapper">
              <tr
                style={{
                  border: "1px solid rgb(240 240 240)",
                }}
              >
                <th></th>
                <th>NAME</th>
                <th>DONE</th>
                <th>CORRECT</th>
                <th>INCORRECT</th>
                <th>SCORE</th>
                <th>{/* ACTIONS */}</th>
              </tr>
            </div>
            {modulesSection}
          </table>
        </div>
      </main>
    );
  }

  let filtersSidebar;
  if (isLoadingExamBoards) {
    filtersSidebar = (
      <div
        style={{
          display: "grid",
          height: "100%",
        }}
      >
        <Loader
          size="md"
          content="Loading..."
          style={{
            margin: "auto",
          }}
        />
      </div>
    );
  } else {
    filtersSidebar = (
      <div className="container-fluid">
        <div className="table-top-bar">
          <h4>Exam boards</h4>
        </div>

        <form
          style={{
            marginBottom: "60px",
          }}
          onSubmit={async (e) => {
            e.preventDefault();
            // const checkedBoxes = document.querySelectorAll('.checkbox-wrapper input[type="checkbox"]:checked')
            // console.log(checkedBoxes)

            await getModules(examBoardsSelected);
          }}
        >
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              name="All"
              id="All"
              defaultChecked
              disabled={examBoardFiltersApplied}
              onClick={(e) => {
                if ((e.target as HTMLInputElement).checked) {
                  clearExamBoardsSelection();
                }
              }}
            />
            <label htmlFor="All">All ({subjectTotalQuestions})</label>
          </div>

          {examBoards.map((examBoard) => {
            return (
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name={examBoard.name}
                  id={String(examBoard.id)}
                  disabled={examBoardFiltersApplied}
                  onClick={(e) => {
                    (
                      document.getElementById("All") as HTMLInputElement
                    ).checked = false;
                    const idOfCheckboxClickedOn = (e.target as HTMLInputElement)
                      .id;

                    if ((e.target as HTMLInputElement).checked) {
                      // appends the exam board to the `examBoardsSelected` state
                      setExamBoardsSelected([
                        ...examBoardsSelected,
                        idOfCheckboxClickedOn,
                      ]);
                    } else {
                      // removes the exam board from the `examBoardsSelected` state
                      setExamBoardsSelected(
                        examBoardsSelected.filter(
                          (examBoard) => examBoard !== idOfCheckboxClickedOn,
                        ),
                      );
                    }
                  }}
                />
                <label htmlFor={String(examBoard.id)}>
                  {examBoard.name} ({examBoard._count.questions})
                </label>
              </div>
            );
          })}

          <BootstrapButton
            variant={examBoardFiltersApplied ? "dark" : "outline-dark"}
            type="submit"
            disabled={examBoardFiltersApplied}
            style={{
              width: "90%",
              marginTop: "10px",
            }}
          >
            {examBoardFiltersApplied
              ? "Filters Applied"
              : `Apply ${
                  examBoardsSelected.length !== 0
                    ? `(${examBoardsSelected.length})`
                    : ""
                } filters`}
          </BootstrapButton>

          {examBoardFiltersApplied && (
            <BootstrapButton
              variant="outline-danger"
              size="sm"
              style={{
                marginTop: "10px",
                width: "90%",
              }}
              onClick={async () => {
                clearExamBoardsSelection();
                await getModules([]);
              }}
            >
              Clear filters
            </BootstrapButton>
          )}
        </form>

        {/* <h4>Progress</h4>
          <div>
            <div>
              <ProgressBarCicularCut progress={50} steps={1064} type="fraction" color="#316aff" text="questions done"></ProgressBarCicularCut>
              <a href="#"> View more</a>
            </div>
            <div style={{
              position: 'relative',
              top: '-20px'
            }}>
              <ProgressBarCicularCut progress={73} steps={120} type="percentage" color="#20BB27" text="average score"></ProgressBarCicularCut>
              <a href="#"> View more</a>
            </div>
          </div>   */}
      </div>
    );
  }

  return (
    <div className="page-wrapper" id="ModulesListPage">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title={subjectName + " Topics"} />

        <div
          className="row"
          style={{
            height: isLoadingModules ? "100%" : "unset",
          }}
        >
          <div className="col-lg-12 col-xl-12">{mainSection}</div>

          {/* <div className="col-lg-3 col-xl-2" id="modules-page-filter-sidebar">
            {filtersSidebar}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ModulesListPage;
