import { Button } from "react-bootstrap";
import { ImCross, ImNext } from "react-icons/im";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Question } from "../../types";
import { useEffect } from "react";

const PracticePageSidebar = (props: {
  questions: Question[];
  submittedQuestionIds: Set<number>;
  selectedAnswers: {
    [key: number]: number;
  };
  maxSeenQuestionIndex: number;
  currentQuestionIndex: number;
  onQuestionClicked: (index: number) => void;
}) => {
  useEffect(() => {
    const element = document.getElementById(`q${props.currentQuestionIndex + 1}`);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [props.currentQuestionIndex]);

  const questions = props.questions.slice(0, props.maxSeenQuestionIndex + 1);

  const numberCorrect = Array.from(props.submittedQuestionIds).filter(
    (questionId) => {
      const selectedAnswerOptionId = props.selectedAnswers[questionId];
      const correctAnswerOptionId = questions
        .filter((question) => question.id === questionId)[0]
        .answerOptions.filter((answer) => answer.isCorrect)[0].id;

      return selectedAnswerOptionId === correctAnswerOptionId;
    },
  ).length;

  const numberIncorrect = Array.from(props.submittedQuestionIds).filter(
    (questionId) => {
      const selectedAnswerOptionId = props.selectedAnswers[questionId];
      const correctAnswerOptionId = questions
        .filter((question) => question.id === questionId)[0]
        .answerOptions.filter((answer) => answer.isCorrect)[0].id;

      return selectedAnswerOptionId !== correctAnswerOptionId;
    },
  ).length;

  const percentage =
    Math.round((numberCorrect / props.submittedQuestionIds.size) * 100) || 0;

  const questionButtons = questions.map((question, index) => {
    const isViewingThisQuestion = props.currentQuestionIndex === index;
    const questionNumber = index + 1;
    if (!props.submittedQuestionIds.has(question.id)) {
      return (
        <li>
          <Button
            id={`q${questionNumber}`}
            style={{
              fontWeight: isViewingThisQuestion ? "bold" : "normal",
              fontSize: isViewingThisQuestion ? "larger" : "small",
              transition: "all 0.3s ease",
              boxShadow: isViewingThisQuestion
                ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                : undefined,
            }}
            onClick={(e) => {
              props.onQuestionClicked(index);
            }}
            size="sm"
            variant={isViewingThisQuestion ? "light" : "light"}
          >
            <ImNext size={isViewingThisQuestion ? 16 : 14} /> Q{questionNumber}
          </Button>
        </li>
      );
    }

    const selectedAnswerOptionId = props.selectedAnswers[question.id];
    const correctAnswerOptionId = question.answerOptions.filter(
      (answer) => answer.isCorrect,
    )[0].id;

    if (selectedAnswerOptionId === correctAnswerOptionId) {
      return (
        <li>
          <Button
            id={`q${questionNumber}`}
            style={{
              fontWeight: isViewingThisQuestion ? "bold" : "normal",
              fontSize: isViewingThisQuestion ? "larger" : "small",
              transition: "all 0.3s ease",
              boxShadow: isViewingThisQuestion
                ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                : undefined,
            }}
            onClick={(e) => {
              props.onQuestionClicked(index);
            }}
            size="sm"
            variant={isViewingThisQuestion ? "light" : "light"}
          >
            <IoIosCheckmarkCircle
              size={isViewingThisQuestion ? 18 : 16}
              color="green"
            />{" "}
            Q{questionNumber}
          </Button>
        </li>
      );
    } else {
      return (
        <li>
          <Button
            id={`q${questionNumber}`}
            style={{
              fontWeight: isViewingThisQuestion ? "bold" : "normal",
              fontSize: isViewingThisQuestion ? "larger" : "small",
              transition: "all 0.3s ease",
              boxShadow: isViewingThisQuestion
                ? "0 1px 10px rgba(0, 0, 0, 0.1)"
                : undefined,
            }}
            onClick={(e) => {
              props.onQuestionClicked(index);
            }}
            size="sm"
            variant={isViewingThisQuestion ? "light" : "light"}
          >
            <ImCross
              color="white"
              size={isViewingThisQuestion ? 15 : 14}
              style={{
                backgroundColor: "red",
                // color: isViewingThisQuestion ? "red" : "white",
                borderRadius: "10px",
                padding: "3px",
                marginRight: "5px",
              }}
            />
            Q{questionNumber}
          </Button>
        </li>
      );
    }
  });

  return (
    <div className="questions-progress-tracker">
      <div className="sticky-top-part">
        <h5 className="mb-4">
          Score <span style={{ float: "right" }}>{percentage}%</span>
        </h5>
        <ul>
          <li>
            <IoIosCheckmarkCircle color="green" size={20} /> Correct{" "}
            <span className="progress-total-correct">{numberCorrect}</span>
          </li>
          <li>
            <ImCross
              color="white"
              size={18}
              style={{
                backgroundColor: "red",
                borderRadius: "10px",
                padding: "5px",
              }}
            />{" "}
            Incorrect{" "}
            <span className="progress-total-incorrect">{numberIncorrect}</span>
          </li>
        </ul>
      </div>

      <div className="past-questions-tracker">
        <ul>{questionButtons}</ul>
      </div>
    </div>
  );
};

export default PracticePageSidebar;
