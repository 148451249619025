import CourseCard from "../../components/CourseCard/CourseCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Topbar } from "../../components/Topbar/Topbar";
import { ReactComponent as WelcomeBackIllustration } from "../../assets/illustration-welcome-back.svg";
import AssignmentSidebar from "../../components/AssignmentSidebar/AssignmentSidebar";
import Button from "../../components/Button/Button";
import { useEffect, useState } from "react";
import "./Dashboard.scss";
import { useUser } from "../../context/UserContext";
import toast from "react-hot-toast";

const Dashboard = () => {
  const { enrolments, fetchEnrolments, user } = useUser();
  const name = `${user?.firstName} ${user?.lastName}`;
  const studentView = user?.type === "student";
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getClasses = async () => {
      try {
        await fetchEnrolments();
        setIsLoading(false);
      } catch (err) {
        toast.error("Error loading dashboard. Please seek support.");
        console.error(err);
      }
    };

    getClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let classesSection = <p>Loading...</p>;

  if (!isLoading) {
    if (enrolments.length === 0) {
      if (studentView) {
        classesSection = <p>You are not enrolled in any classes yet.</p>;
      } else {
        classesSection = (
          <div>
            <p>You have no classes set up yet.</p>
            <Button text="Add class" />
          </div>
        );
      }
    } else {
      classesSection = (
        <div className="courses-wrapper">
          {enrolments.map((e) => (
            <CourseCard {...e} />
          ))}
        </div>
      );
    }
  }

  return (
    <div className="page-wrapper" id="Dashboard">
      <Sidebar />
      <div className="internal-page-wrapper">
        <Topbar title="Dashboard" />
        <main>
          <div id="main-dashboard">
            <div id="welcome-back">
              <div id="message">
                <h3>Welcome back, {name}!</h3>
                {studentView ? (
                  <>
                    <p>
                      Your progress this week is awesome. Let's keep it up and
                      ace our exams!
                    </p>
                    {/* <Button text="Find course" /> */}
                  </>
                ) : (
                  <p>
                    Keep track of your students enhance their learning process
                    through MCQ assignments!
                  </p>
                )}
              </div>
              <WelcomeBackIllustration
                style={{
                  backgroundColor: "#E2E8F6",
                  padding: "15px 15px 0 15px",
                  borderRadius: "0 10px 10px 0",
                }}
              />
            </div>
            <div id="titleWrapper">
              <h2>Your Classes</h2>
              {studentView ? undefined : <Button text="Create class" />}
            </div>

            {classesSection}
          </div>

          {
            // Only show assignment sidebar for students. Don't show for teachers.
            studentView && <AssignmentSidebar />
          }
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
